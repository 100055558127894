<template>

	<div class="main-contents">
		<div class="mem_container">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div v-if="pageId == '3'" class="title-txt">공급기업 지정 프로젝트 &gt; 소속인재 프로젝트 참여 현황<br/>
						<p><span class="prj_ct">소속인재 프로젝트 리스트</span></p>
					</div>
					<div v-else-if="pageId == '1'" class="title-txt">일반 프로젝트 &gt; 소속인재 프로젝트 참여 현황<br/>
						<p><span class="prj_ct">소속인재 프로젝트 리스트</span></p>
					</div>
					<div v-else class="title-txt">기술인재 정보 &gt; 소속인재 프로젝트 참여 현황<br/>
						<p><span class="prj_ct">소속인재 프로젝트 리스트</span></p>
					</div>
				</div>
				<div class="contents-body prj">
					<div class="filter">
						<div class="search_box sco">
							<SelectComp v-if="pageId == '5'" type="select" list="3:공급기업지정,1:일반" :isAll="true" title="프로젝트 구분" v-model="input.projectDivCd"/>

							<SelectComp type="select" list="2:참여예정,1:참여중,9:참여완료,99:중도철수" :isAll="true" title="참여 상태" v-model="input.projectRecrStatus"/>
							
							<SelectComp type="select" list="05:기술사,04:특급,03:고급,02:중급,01:초급" :isAll="true" title="투입등급" v-model="input.projectJoinTechGradeCd"/>

							<SelectComp type="select" list="01:자사,99:타사" :isAll="true" title="소속 상태" v-model="input.asgStatus"/>

							<SelectComp type="select" list="1:프로젝트명,2:참여인재명" v-model="input.searchGubun"/>						

							<InputComp type="text" classNm="search_box int" :placeholder="input.searchGubun == '1' ? '프로젝트명' : '참여인재명'" v-model="input.searchKeyword" @keyup.enter="getList('1')" />
							<div class="search_btn" @click="getList('1')"><img class="search" src="/images/search_btn.png" alt="검색버튼"/></div>
						</div>
						<div class="filter_box prj_list">
							<ul>
								<li><img :class="input.orderGubun == '1' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('1')">등록일 순</p><span>ㅣ</span></li>
								<li><img :class="input.orderGubun == '2' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('2')">수행율 높은 순</p><span>ㅣ</span></li>
								<li><img :class="input.orderGubun == '3' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('3')">수행율 낮은 순</p></li>
							</ul>
						</div>
					</div>
					<!-- 전체프로젝트 리스트 -->
					<div class="Board type3">
						<!-- 현황 다운로드 버튼 -->
						<div class="humaninfo-box dco">
							<div class="btn-humaninfo particiStatus" @click="statusDown()">
								<img src="/images/status_down_btn.png">
								<p>참여현황 다운로드</p>
							</div>
						</div>
						<table class="Board_type3 demand sco">
							<colgroup>
								<!-- <col width="5%">
								<col width="8%">
								<col width="34%">
								<col width="16%">
								<col width="6%">
								<col width="9%">
								<col width="13%">
								<col width="9%"> -->

								<col width="4%">
								<col width="8%">
								<col width="33%">
								<col width="15%">
								<col width="6%">
								<col width="8%">
								<col width="6%">
								<col width="7%">
								<col width="13%">
								
							</colgroup>
							<thead>
								<tr>
									<th>NO</th>
									<th>구분</th>
									<th>프로젝트명</th>
									<th>참여기간</th>
									<th>투입등급</th>
									<th>참여상태</th>
									<th>프로젝트 참여율</th>
									<th>참여인재</th>
									<th>원소속</th>
									
								</tr>
							</thead>
							<tbody>
								<tr v-for="(project, index) in projectList" :key="index">
									<td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</td>
									<td class="score">
										<span v-if="project.projectDivCd == '01'">일반</span>
										<span v-else-if="project.projectDivCd == '03'">공급기업 지정</span>
									</td>
									<td class="project">
										<div class="rc_tag" v-if="project.projectMark == 'Y'">
											<div class="rc_mark_black">제안단계</div>
										</div>
										<p>{{project.projectNm}}</p><div class="btn dt" @click="clickProjectInfo(project.projectSeq, project.projectRecrStatus)">자세히보기 &#9654;</div>
									</td>
									<td class="score">{{project.projectRecrStartEndYyyymmdd}}</td>
									<td class="score"><grade-img type="LICENSE_TECH_ADM" :grade="project.projectJoinTechGradeCd"/></td>
									<td class="score">
										<span v-if="project.projectRecrStatus == '1'"><div class="light"></div>참 여 중<nbsp n="2" /></span>
										<span v-else-if="project.projectRecrStatus == '2'"><div class="light yw"></div>참여예정</span>
										<span v-else-if="project.projectRecrStatus == '9'"><div class="light dg"></div>참여완료</span>
										<span v-else-if="project.projectRecrStatus == '99'"><div class="light rd"></div>중도철수</span>
									</td>
									<td class="score">
										<!-- <img :src="'/images/' + (project.exeRecrPer == undefined || project.exeRecrPer == '' ? '0' : project.exeRecrPer) + '_percent.png'" alt="참여율"/> -->
										{{ project.exeRecrPer == undefined || project.exeRecrPer == '' ? '0%' : project.exeRecrPer + '%' }}
									</td>
									<td v-if="project.mberInfoYn == 'Y'" class="score btn_cursor" @click="openPop(project.mberSeq)">{{project.mberNm}} &#9654;</td>
									<td v-else class="score">{{project.mberNm}}</td>
									<td class="score">{{ project.corpNm }}</td>
									<!-- <td class="score">{{project.asgYn}}</td> -->
									
								</tr>

								<!-- 프로젝트가 없을 경우 -->
								<tr v-if="pageInfo.totalRecordCount == 0">
									<td colspan="9" class="none">프로젝트가 없습니다!</td>
								</tr>

							</tbody>
						</table>
					</div>
					
					<!--페이징 컴포넌트 -->
					<PagingComp :pageInfo="pageInfo" @page-click="goPage"/>
					<!--// 페이징 컴포넌트 -->

				</div>
			</div>
		</div>
	</div>

</template>

<script>

import PagingComp from '@/components/PagingComp.vue';
import gradeImg from "@/components/highpro/GradeImg.vue";

export default {
	
	components : {
		PagingComp ,
		gradeImg ,
	},

	data() {
		return {
			input: {
				pageIndex : '1',
				orderGubun : '1',		// 1:등록일 순, 2:참여율 높은순, 3:참여율 낮은순
				searchGubun : '1',		// 1:프로젝트명, 2:참여인재명
				projectDivCd : this.$route.params.projectDivCd || '',
				projectRecrStatus : this.$route.params.projectRecrStatus || '',	// 프로젝트상태 (참여상태) 1:참여중, 2:참여예정, 9:완료
				projectJoinTechGradeCd : '', // 프로젝트 투입등급  01:초급, 02:중급, 03:고급, 04:특급, 05:기술사
				asgStatus : '', // 소속 상태 01:자사, 99: 타사 
				searchKeyword : '',
			}, // 검색조건		
			
			pageId : '',
			projectList : [],
			pageInfo : {},
		};
	},
	bforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
		
		// this.input.projectDivCd = this.$route.name.substr(8,1) == '1' ? '3' : '1';

		if(this.$route.name.substr(8,1) == '1') {
			this.pageId = '3'
			this.input.projectDivCd = '3'
		}else if(this.$route.name.substr(8,1) == '2') {
			this.pageId = '1'
			this.input.projectDivCd = '1'
		}else {
			this.pageId = '5'
		}	
		
	},
	mounted() {
		// console.log('mounted');

		if(this.$route.params != undefined && this.$route.params.projectRecrStatus != undefined) {
			this.input.projectRecrStatus = this.$route.params.projectRecrStatus;
		}

		this.getList();
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		getList(div, isScroll = false) {

			if(div) this.input.pageIndex = 1;
			this.$.httpPost('/api/prj/sco/getProjectList', this.input)
				.then(res => {
					// console.log(res.data);
					this.projectList	= res.data.list;
					this.pageInfo		= res.data.pageInfo;
					
					if(isScroll) {
						window.scroll(0, 0);
					}
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},

		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList('', true);
		},

		orderSearch(orderGubun) {
			this.input.orderGubun = orderGubun;
			this.input.pageIndex = 1;
			this.getList();
		},
		
		clickProjectInfo(projectSeq, projectRecrStatus) {
			var param = {
				projectSeq : projectSeq,
				projectRecrStatus : projectRecrStatus
			};
			this.$.popup('/sco/prj/PRJ401P01', param, {width:0, height:0})
				// .then(res => {})
				// .catch(err => {})
				;
		},
		
		openPop(seq) {
			var div = 'tec';
			this.$.popup('/dco/gmg/myp/MYP201P01', { seq, div }); 
		},
		
		statusDown(){
			var param = {
				mberList : this.projectList
			}
			this.$.fileDownload ('/api/prj/getPrjJoinMberFileDown', param)
			.then(() => {
					// console.log("res : " + res);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		} 

		

		// 참여현황 엑셀다운
		/*
		prjtest() {
			var param = {
				mberList : this.projectList,
			}
			this.$.fileDownload ('/api/prj/getPrjJoinMberFileDown', param)
				.then(() => {
					// console.log("res : " + res);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		} */
		
	},
};
</script>
